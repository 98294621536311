import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import BlogPostPreviewList from "../components/BlogPostPreviewList";

import {
  AuthorProperties,
  BlogPostNodeInterface
} from "../interfaces/blogPost";

import { ChildImageSharp } from "../interfaces/images";
import LinkCTAs from "../components/LinksCTAs";
import HelenCard from "../components/HelenCard";
import { variables } from "../styles/variables";
import { EmotionStyles } from "../interfaces/emotion";
import { breakpointMixins } from "../styles/breakpoints";
import { SiteInterface } from ".";

export const query = graphql`
  query BlogListQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`;

interface BlogListPageProps {
  data: {
    site: SiteInterface;
    posts: {
      edges: Array<BlogPostNodeInterface>;
    };
    author: AuthorProperties;
    helenImage: ChildImageSharp;
  };
  errors: Array<GraphQLError>;
}

const styles: EmotionStyles = {
  container: {
    paddingTop: variables.spacing.medium,
    [breakpointMixins.lg]: {
      paddingTop: variables.spacing.large
    }
  }
};

const BlogListPage: React.FC<BlogListPageProps> = ({ data, errors }) => {
  const { posts, site } = data || {};
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <>
      {site && (
        <SEO
          title={`The Blog | ${site.title}`}
          description={site.description}
          keywords={site.keywords}
        />
      )}
      <div css={styles.container}>
        <BlogPostPreviewList nodes={posts.edges} title="The Blog" />
        <HelenCard />
        <LinkCTAs />
      </div>
    </>
  );
};

export default BlogListPage;
